function degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
}
  
function distanceBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    var earthRadius = 6371000; // in meters

    var dLat = degreesToRadians(lat2-lat1);
    var dLon = degreesToRadians(lon2-lon1);

    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return earthRadius * c;
}
global.distanceBetweenEarthCoordinates = distanceBetweenEarthCoordinates;

function getSpiralPositions(nb_points, dist) {
    // see https://math.stackexchange.com/questions/2335055/placing-points-equidistantly-along-an-archimedean-spiral-from-parametric-equatio
    let positions = Array();
    let r = dist * 1/(Math.PI*2); //here we set the spiral turns distance to "dist".
    positions.push([0, 0]); // don't move the first point
    for(n=0; n<nb_points-1; n++){ // NOTE: the first point is already pushed
        let tau = Math.sqrt(2*(n+4)*dist/r); // NOTE: exclude the 4 first points of the spiral
        let x = r*tau*Math.cos(tau);
        let y = r*tau*Math.sin(tau);
        positions.push([x, y]);
    }
    return positions;
}

function getConcentricCirclesPositions(nb_points, dist) {
    // see https://math.stackexchange.com/questions/2335055/placing-points-equidistantly-along-an-archimedean-spiral-from-parametric-equatio
    let positions = Array();
    let current_circle_nb = 1;
    let n = 0;
    while(n<nb_points){
        let r = current_circle_nb*dist;
        let P = 2*Math.PI*r;
        let nb_pts_on_circle = Math.round(P / dist);
        for(sub_n=0; sub_n<nb_pts_on_circle; sub_n++){
            let theta = sub_n * 2 * Math.PI / nb_pts_on_circle;
            positions.push([r*Math.cos(theta), r*Math.sin(theta)]);
            n++;
        }
        current_circle_nb++;
    }
    return positions;
}

function getTranslatedCoordinates(lat, lon, dx, dy){
    // this is an approximation !
    let outLat = lat + dy/111111;
    let outLon = lon + dx/(111111*Math.cos(degreesToRadians(lat)));
    return [outLat, outLon];
}

function workaroundTooCloseEvents(events, dist_threshold=5.0, separation_dist=10) {
    // 1- build "too_close_groups", an array that contains too close events by groups.
    let events_count = events.length;
    too_close_groups = Array(); // this will be [ [g1_ev1, g1_ev2, ...], [g2_ev1, g2_ev2, ...], ... ]
    //Loop once on all event pairs, with ev1 != ev2
    for(ev1_id=0; ev1_id<events_count-1; ev1_id++){
        for(ev2_id=ev1_id+1; ev2_id<events_count; ev2_id++){
            let ev1=events[ev1_id];
            let ev2=events[ev2_id];
            let dist = distanceBetweenEarthCoordinates(ev1.latitude, ev1.longitude,ev2.latitude, ev2.longitude);
            if(dist<dist_threshold){ // ev1 and ev2 are too close with each other.
                //The following is non-trivial. Loop on the existing "too_close_groups" and check whether one of them contains ev1_id or ev2_id:
                //      if none contains ev1 or ev2 (expanded_groups_ids.size==0): create a new group with ev1_id and ev2_id;
                //      if only one contains ev1 or ev2 (expanded_groups_ids.size==1): add ev1_id or ev2_id to the corresponding group.
                //      if one group contains ev1, and an other contains ev2 (expanded_groups_ids.size==2): merge the two groups.
                //This way, an event can't belong to two groups at any time (expanded_groups_ids.size!>2). 
                let expanded_groups_ids = new Set();
                too_close_groups.forEach(function(too_close_group, too_close_group_id){
                    if(too_close_group.has(ev1_id)){
                        too_close_group.add(ev2_id);
                        expanded_groups_ids.add(too_close_group_id);
                    }
                    if(too_close_group.has(ev2_id)){
                        too_close_group.add(ev1_id);
                        expanded_groups_ids.add(too_close_group_id);
                    }
                })
                if(expanded_groups_ids.size==0){ // create a new group of two events
                    too_close_groups.push(new Set([ev1_id, ev2_id]));
                } else if(expanded_groups_ids.size==2){ // merge the two groups
                    too_close_groups[expanded_groups_ids[1]].forEach(function(el){
                        too_close_groups[expanded_groups_ids[0]].add(el);
                    })
                    too_close_groups.splice(expanded_groups_ids[1],1);
                } // else groups_increased_ids.size==1 -> already handeled above with too_close_group.add(...)
            }
        }
    }

    // 2- loop on "too_close_groups" and articifically change the events location in a "snail" fashion.
    too_close_groups.forEach(function(too_close_group){
        group_size = too_close_group.size;
        offset_positions = getSpiralPositions(group_size, separation_dist); //offset positions as getSpiralPositions is centered around origin.
        let center_coords = [ events[Array.from(too_close_group)[0]].latitude, events[Array.from(too_close_group)[0]].longitude];
        Array.from(too_close_group).forEach(function(el, id){
            let ev = events[el];

            [lat, lon] = getTranslatedCoordinates(center_coords[0], center_coords[1], offset_positions[id][0], offset_positions[id][1]);
            ev.latitude = lat;
            ev.longitude = lon;
        })
    })
}
global.workaroundTooCloseEvents = workaroundTooCloseEvents;
